import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/base/layout";
import SEO from "../components/base/seo";
import Products from "../components/products/products";
const style = {
  paddingTop: "100px",
  paddingBottom: "100px",
};

const TestPage = () => {
  return (
    <Layout>
      <SEO title="Advanced Example" />
      <div className="container" style={style}>
        <Products>
          <div title="Test CMO" subtitle="$1" id="test-cmo">
            <p>This is a test product</p>
          </div>
        </Products>
      </div>
      <section id="get_your_report">
        <div className="form-section">
          <div className="container">
            <p>Here is the embedded typeform:</p>
            <Helmet>
              <script src="//embed.typeform.com/next/embed.js"></script>
            </Helmet>
            <div
              data-tf-widget="LvsVQubr"
              data-tf-opacity="100"
              style={{ width: "100%", height: "600px" }}
            ></div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TestPage;
